<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Banner - Setores</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click="loadBannerSectors">
                <v-icon dark class="mdi mdi-sync"></v-icon>Atualizar
              </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'bannersector.add'}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Novo Setor</v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="bannersector" :search="search">

            <template v-slot:[`item.bnnr_sec_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.bnnr_sec_status" @change="onChangeEventHandler(item.bnnr_sec_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editBannerSector(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteBannerSector(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

            </template>

        
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Voce procurou por "{{ search }}" nenhum item localizado.
                </v-alert>
            </template>
        </v-data-table>
    </v-card>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    created() {
        this.loadBannerSectors()
    },
    computed: {

    },
    name: "BannerSectorComponent",
    data() {
        return {
            search: '',
            headers: [{
                    text: 'Nome',
                    value: 'bnnr_sec_nome'
                },
                {
                    text: 'Status',
                    value: 'bnnr_sec_status'
                },
                {
                    text: 'Ações',
                    align: 'center',
                    sortable: false,
                    value: 'acoes'
                }
            ],
            bannersector: [],
            base_url: URL_BASE
        }
    },
    methods: {
        loadBannerSectors() {
            this.$store.dispatch('loadBannerSectors')
                .then(response => {
                    this.bannersector = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
        editBannerSector(item) {
            this.$router.push({
                name: 'bannersector.edit',
                params: {
                    bnnr_sec_id: item.bnnr_sec_id
                }
            });
        },
        deleteBannerSector(item, index) {
            this.$swal({
                title: 'Você tem certeza?',
                text: "Você não poderá reverter isso",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#D32F2F',
                cancelButtonColor: '#1976D2',
                confirmButtonText: 'Sim, Exclua!'
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch('destroyBannerSector', item.bnnr_sec_id)
                        .then(response => {
                            if (response) {
                                this.$swal({
                                    position: "center",
                                    icon: "success",
                                    title: 'Sucesso',
                                    text: 'Dados excluídos com sucesso',
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.bannersector.splice(index, 1);
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: 'Opssss!',
                                    text: 'Erro ao excluir dados',
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: 'Opssss!',
                                text: 'Algo errado aconteceu!',
                                showConfirmButton: true,
                                timer: 6000
                            });
                        })
                }
            })

        },
        onChangeEventHandler(bnnr_sec_id, value) {
            this.$store.dispatch('updateBannerSectorStatus', {
                    bnnr_sec_id: bnnr_sec_id,
                    bnnr_sec_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        type: "success",
                        title: 'Sucesso',
                        text: 'Status atualizado com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Opssss!',
                        text: 'Erro ao atualizar status',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}
</style>
